import React from "react";
import { Navbar, Footer, AllTalents } from "./components";
import { Provider } from "./components/contextHook/context";
import ReactGA from 'react-ga'; //Google Analytics 


import "./App.scss";

// Google Analytics function initialization
function initializeReactGA() {
  ReactGA.initialize('UA-126129205-3');
  ReactGA.pageview('/');
}

function App() {
  initializeReactGA()
  return (
    <Provider>
      <React.Fragment>
        <Navbar />

        <AllTalents />

        <Footer />
      </React.Fragment>
    </Provider>
  );
}

export default App;
