import React from "react";
import axios from "axios";
import { getImg } from "../../helpers";

export const Context = React.createContext();

export class Provider extends React.Component {
  state = {
    talents: []
  };

  componentDidMount() {
    const fetchTalents = async () => {
      const talents = await axios.get(
        "https://theshortcut.org/wp-json/wp/v2/talents?per_page=100"
      );

      await Promise.all(
        talents.data.map(async tal => {
          const pic = await getImg(tal.featured_media);
          tal["profile_pic"] = pic;
        })
      );

      this.setState({ talents: talents.data });
    };
    fetchTalents();
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const Consumer = Context.Consumer;
