import axios from "axios";

const getImg = async featured_img => {
  const data = await axios.get(
    `https://theshortcut.org/wp-json/wp/v2/media/${featured_img}`
  );

  return data.data;
};

const searchResult = async (searchValue, talents, type) => {
  try {
    const search_value = await searchValue;
    const final = [];
    if (type === "All") {
      await talents.forEach(each => {
        if (
          each.title.rendered
            .toLowerCase()
            .includes(search_value.toLowerCase()) ||
          each.content.rendered
            .toLowerCase()
            .includes(search_value.toLowerCase()) ||
          each.hashtags.toLowerCase().includes(search_value.toLowerCase())
        ) {
          final.push(each);
        }
      });
    } else if (type === "Name") {
      await talents.forEach(each => {
        if (
          each.title.rendered.toLowerCase().includes(search_value.toLowerCase())
        ) {
          final.push(each);
        }
      });
    } else if (type === "Content") {
      await talents.forEach(each => {
        if (
          each.content.rendered
            .toLowerCase()
            .includes(search_value.toLowerCase())
        ) {
          final.push(each);
        }
      });
    } else {
      await talents.forEach(each => {
        if (each.hashtags.toLowerCase().includes(search_value.toLowerCase())) {
          final.push(each);
        }
      });
    }

    return [...final];
  } catch (err) {
    console.log(err);
  }
};

export { getImg, searchResult };
