import React from "react";
import { Context } from "../contextHook/context";

import TalentCard from "./TalentCard";
import Pagination from "../pagination/Pagination";
import SearchBar from "../searchBar/SearchBar";
import { ReactComponent as Spinner } from "../../icons/spinner.svg";
import NoMatch from "../layout/NoMatch";

import { searchResult } from "../../helpers";

class AllTalents extends React.Component {
  state = {
    currentPage: 1,
    perPage: 12,
    results: [],
    no_match: null
  };

  paginate = number => {
    return () => {
      this.setState({ currentPage: number });
    };
  };

  previousButton = () => {
    const toPage = this.state.currentPage - 1;
    this.setState({ currentPage: toPage <= 1 ? 1 : toPage });
  };

  nextButton = () => {
    const toPage = this.state.currentPage + 1;
    return this.setState({ currentPage: toPage });
  };

  searchProcess = async (searchValue, type, array) => {
    const results = await searchResult(searchValue, array, type);
    if (!results.length) {
      return this.setState({
        ...this.state,
        no_match: "There is no match, try other keywords or change the filter"
      });
    }

    this.setState({ ...this.state, results: results, no_match: null });
  };

  render() {
    const { currentPage, perPage, results, no_match } = this.state;
    const { talents } = this.context;
    const lastItem = currentPage * perPage;
    const firstItem = lastItem - perPage;
    let currentItems;

    if (results.length) {
      currentItems = results.slice(firstItem, lastItem);
    } else {
      currentItems = talents.slice(firstItem, lastItem);
    }

    return talents.length ? (
      <div className='container'>
        <SearchBar searchProcess={this.searchProcess} talents={talents} />
        {no_match ? (
          <NoMatch message={no_match} />
        ) : (
          <React.Fragment>
            <Pagination
              perPage={perPage}
              totalItems={results.length ? results.length : talents.length}
              paginate={this.paginate}
              currentPage={currentPage}
              previousButton={this.previousButton}
              nextButton={this.nextButton}
            />

            <div className='row my-3'>
              {currentItems.map(item => {
                return <TalentCard key={item.id} data={item} pic={null} />;
              })}
            </div>
            <Pagination
              perPage={perPage}
              totalItems={results.length ? results.length : talents.length}
              paginate={this.paginate}
              currentPage={currentPage}
              previousButton={this.previousButton}
              nextButton={this.nextButton}
            />
          </React.Fragment>
        )}
      </div>
    ) : (
      <div
        className='row'
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "70vh"
        }}
      >
        <Spinner />
      </div>
    );
  }
}

AllTalents.contextType = Context;
export default AllTalents;
