import React from "react";

const SearchBar = ({ talents, searchProcess }) => {
  const [keyword, setKeyword] = React.useState("");
  const [typeSelect, setTypeSelect] = React.useState("All");

  return (
    <div className='row'>
      <div className='col-9 mx-auto'>
        <form
          onSubmit={e => {
            e.preventDefault();
            searchProcess(keyword, typeSelect, talents);
          }}
        >
          <div id='searchBar'>
            <input
              name='search'
              type='text'
              placeholder='Search...'
              className='searchInput'
              autoComplete='off'
              onChange={e => setKeyword(e.target.value)}
            />
          </div>
          <div className='form-group mt-3'>
            <label htmlFor='filter'>Search By: </label>
            <select id='filter' onChange={e => setTypeSelect(e.target.value)}>
              <option value='All'>All</option>
              <option value='Name'>Name</option>
              <option value='Content'>Content</option>
              <option value='Hashtags'>Hashtags</option>
            </select>
          </div>
        </form>
      </div>
      <div className='col-5' />
    </div>
  );
};

export default SearchBar;
