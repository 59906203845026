import React from "react";
import { ReactComponent as HiredBadge } from "../../icons/Hired.svg";
import { ReactComponent as Catalyst } from "../../icons/Catalyst.svg";
import { ReactComponent as Intern } from "../../icons/Intern.svg";

class TalentCard extends React.Component {
  state = {
    data: null
  };

  render() {
    const { data } = this.props;
    const { categories, profile_pic } = data;
    return (
      <div className='col-lg-3 col-md-4'>
        <div className='card'>
          <div className='card-body-under'>
            <img
              src={profile_pic.source_url}
              className='card-img-top feature-img explainer'
              alt='pretty face'
            />
            <div className='card-title'>{data.title.rendered}</div>
            <div className='badges'>
              {categories.includes(24) && (
                <HiredBadge
                  style={{
                    width: "20%"
                  }}
                />
              )}

              {categories.includes(25) && (
                <Catalyst
                  style={{
                    width: "30%"
                  }}
                />
              )}

              {categories.includes(26) && (
                <Intern
                  style={{
                    width: "20%"
                  }}
                />
              )}
            </div>
            <div className='hashtag'>
              <p className='text-muted'>{data.hashtags}</p>
            </div>
          </div>
          <div className='card-body-upper'>
            <div className='card-title'>{data.title.rendered}</div>
            <div className='social-icons'>
              <a className='left' href={`mailto: ${data.email}`}>
                <i className='far fa-envelope fa-1x' />
              </a>
              <a className='right' href={data.linkedin_profile}>
                <i className='fab fa-linkedin-in fa-1x' />
              </a>
            </div>
            <div className='hashtag-container'>
              <p className='text-muted'>{data.hashtags}</p>
            </div>
            <div
              className='card-texty'
              dangerouslySetInnerHTML={{
                __html: data.content.rendered
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TalentCard;
