import React from "react";
import { ReactComponent as Emoji } from "../../icons/emoji.svg";

const NoMatch = ({ message }) => {
  return (
    <div className='row'>
      <div className='col-md-6 mx-auto text-center'>
        <div className='speech-bubble p-5'>
          <h1>{message}</h1>
        </div>
        <Emoji />
      </div>
    </div>
  );
};

export default NoMatch;
