import React from "react";

class Navbar extends React.Component {
  navLinkRef = React.createRef();

  openHamburber = () => {
    this.navLinkRef.current.classList.toggle("open");
    const links = [...this.navLinkRef.current.children];
    links.forEach(link => {
      link.classList.toggle("fade");
    });
  };
  render() {
    return (
      <nav>
        <a href='https://theshortcut.org' className='img-container'>
          <img src='/img/Shortcut_logo.png' alt='Logo' />
        </a>
        <div className='hamburger' onClick={this.openHamburber}>
          <div className='line' />
          <div className='line' />
          <div className='line' />
        </div>
        <ul className='nav-links' ref={this.navLinkRef}>
          <li>
            <a href='https://theshortcut.org/'>Home</a>
          </li>
          <li>
            <a href='/'>Talents</a>
          </li>
          <li>
            <a href='https://theshortcut.org/home/contact/'>Contact</a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
