import React from "react";

const Footer = props => {
  return (
    <div className='footer'>
      <div className='row'>
        <div className='col'>
          <h1>The Shortcut</h1>
          <p>Maria 01, Lapinlahdenkatu 16,00180 Helsinki</p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
