import React from "react";

const Pagination = ({
  perPage,
  totalItems,
  paginate,
  currentPage,
  previousButton,
  nextButton
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / perPage); i++) {
    pageNumbers.push(i);
  }

  const pag = pageNumbers.map(p => {
    return (
      <li key={p} className='page-item'>
        <button className='page-link' onClick={paginate(p)}>
          {p}
        </button>
      </li>
    );
  });

  return (
    <React.Fragment>
      <div className='row justify-content-center align-items-center'>
        <ul className='pagination'>
          {currentPage === 1 ? (
            <li className='page-item disabled'>
              <button className='page-link '>Previous</button>
            </li>
          ) : (
            <li className='page-item'>
              <button className='page-link' onClick={previousButton}>
                Previous
              </button>
            </li>
          )}

          {pag}

          {currentPage === pageNumbers[pageNumbers.length - 1] ? (
            <li className='page-item disabled'>
              <button className='page-link'>Next</button>
            </li>
          ) : (
            <li className='page-item'>
              <button className='page-link' onClick={nextButton}>
                Next
              </button>
            </li>
          )}
        </ul>
      </div>
      <div className='row justify-content-center align-items-center'>
        <p>Current Page: {currentPage}</p>
      </div>
    </React.Fragment>
  );
};

export default Pagination;
